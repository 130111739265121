import React, { useState } from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import "../styles/Navbar.css";
import { Menu, MenuItem, Typography } from '@material-ui/core';
import NestedMenuItem from "material-ui-nested-menu-item";
//import TourDetails from '../helpers/TourData';
function Navbar(TourDetails) {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  const [menuPosition, setMenuPosition] = useState("");

  const handleonTourClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: 59,
      left: event.pageX
    });
  };
  const handleItemClick = (event, tour) => {
    setMenuPosition(null);
    window.location.href = "/tour/" + tour;
  };

  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <a href="/"> <img src={Logo} /></a>
        <div className="hiddenLinks">
          <Link to="/"> Home </Link>
          <Link className="tourNavMenu" onClick={handleonTourClick} to='/'> Tour </Link>
          <Menu open={!!menuPosition}
          onClose={() => setMenuPosition(null)}
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}>
          <NestedMenuItem label="Domestic" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails && TourDetails.TourDetails.map((element) => {
              if (element.Category === "Domestic")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}

          </NestedMenuItem>
          <NestedMenuItem label="International" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails && TourDetails.TourDetails.map((element) => {
              if (element.Category === "Inter national")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}
          </NestedMenuItem>
          <NestedMenuItem label="Pilgrimage" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails&&TourDetails.TourDetails.map((element) => {
              if (element.Category === "Pilgrimage")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}
          </NestedMenuItem>
        </Menu>
          <Link to="/more"> More </Link>
          <Link to="/about"> About </Link>
          <Link to="/contact"> Contact </Link>

        </div>
      </div>
      <div className="rightSide">
        <Link to="/"> Home </Link>
        <Link className="tourNavMenu" onClick={handleonTourClick} to='/'> Tour </Link>
        <Menu open={!!menuPosition}
          onClose={() => setMenuPosition(null)}
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}>
          <NestedMenuItem label="Domestic" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails&&TourDetails.TourDetails.map((element) => {
              if (element.Category === "Domestic")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}

          </NestedMenuItem>
          <NestedMenuItem label="International" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails&&TourDetails.TourDetails.map((element) => {
              if (element.Category === "International")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}
          </NestedMenuItem>
          <NestedMenuItem label="Pilgrimage" parentMenuOpen={!!menuPosition}
            onClick={handleonTourClick}>
            {TourDetails&&TourDetails.TourDetails.map((element) => {
              if (element.Category === "Pilgrimage")
                return (<MenuItem onClick={(event) => { handleItemClick(event, element.TourName.toLowerCase()) }}>  {element.TourName}</MenuItem>)
            })}
          </NestedMenuItem>
        </Menu>
        <Link to="/ourservices"> Our Services </Link>
        <Link to="/about"> About </Link>
        <Link to="/contact"> Contact </Link>
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </div>
  );
}

export default Navbar;
