import React from "react";
import BannerImage from "../assets/travel-landscape-01.jpg";
import Rajasthan from "../assets/Rajasthan.jpg";
import varanasi from "../assets/varanasi.jpg";
import Andaman from "../assets/andaman.jpg";
import Kerala from "../assets/kerala1.jpg";
import "../styles/Home.css";
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PupularTours from "../components/PopularTripsTile";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import TourDetails from '../helpers/TourData';

function Home(TourDetails) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="home" >

      <Carousel activeIndex={index} onSelect={handleSelect}>
        {TourDetails.TourDetails.length > 0 && TourDetails.TourDetails.map((element) => {
          return (
            <Carousel.Item onClick={() => { window.location.href = "/tour/" + element.TourName.toLowerCase() }} >
              <a href={"/tour/" + element.TourName.toLowerCase()}><div className="imagesection" style={{ backgroundImage: `url(${element.BannerImage})` }}></div></a>
              <Carousel.Caption>
                <h3 onClick={() => { window.location.href = "/tour/" + element.TourName.toLowerCase() }}>{element.TourName}</h3>
              </Carousel.Caption>
            </Carousel.Item>);
        })}
      </Carousel>

      <div className="PopularTripsdiv">
        <h2 className="text-2xl md:text-3xl xl:text-4xl text-black font-bold mb-4 text-center poplulartripHeading"> Most Popular Tours</h2>
        <div className="-mx-4 flex flex-wrap poplulartripSection">
          <Row >
            {TourDetails.TourDetails.length > 0 && TourDetails.TourDetails.map((element) => {
              if (element.Type && (element.Type.toLowerCase() == "popular" || element.Type.toLowerCase() == "both")) {
                return (<Col sm={12} md={4}> <div key={element.TourId} className="w-full sm:w-1/2 md:w-1/3 px-4 mt-4"><PupularTours img={element.TileImage} title={element.TourName} TourData={element} link={'/tour/' + element.TourName.toLowerCase()} /></div></Col>)
              }
            }
            )}
          </Row>
        </div>
      </div>
      <div className="SeasonalTripsDiv">
        <h2 className="text-2xl md:text-3xl xl:text-4xl text-black font-bold mb-4 text-center poplulartripHeading"> Seasonals Tours</h2>
        <div className="-mx-4 flex flex-wrap poplulartripSection">
          <Row >
            {TourDetails.TourDetails.length && TourDetails.TourDetails.map((element) => {
              if (element.Type && (element.Type.toLowerCase() == "seasonal" || element.Type.toLowerCase() == "both")) {
                return (<Col sm={12} md={4}> <div key={element.TourId} className="w-full sm:w-1/2 md:w-1/3 px-4 mt-4"><PupularTours img={element.TileImage} title={element.TourName} TourData={element} link={'/tour/' + element.TourName.toLowerCase()} /></div></Col>)
              }
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Home;
