import React from "react";
import contactUsImage from "../assets/divyanshi-verma-h3SyJYsqYSo-unsplash-1-scaled-e1676371138507.jpg";
import "../styles/Contact.css";

function Contact() {
  return (
    <div>
      <div className="contact">

        <div className="rightSide">

          <div className="contactUsheading"> <h1 > WE LOVE TO HEAR FROM YOU</h1>
            <h3 style={{ paddingTop: "10px" }}>Our door is always open for a </h3>
            <h3 style={{ paddingBottom: "10px" }}> good cup of coffee!</h3></div>

          <form id="contact-form" method="POST">
            <label htmlFor="name">Full Name</label>
            <input name="name" placeholder="Enter full name..." type="text" />
            <label htmlFor="email">Email</label>
            <input name="email" placeholder="Enter email..." type="email" />
            <label htmlFor="message">Message</label>
            <textarea
              rows="6"
              placeholder="Enter message..."
              name="message"
              required
            ></textarea>
            <button type="submit"> Send Message</button>
          </form>
        </div>
        <div
          className="leftSide"
          style={{ backgroundImage: `url(${contactUsImage})` }}
        ></div>
      </div>


      <div >
        <iframe className="mapDiv" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.304948614137!2d73.84845461436869!3d18.515116674174575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c15d3e5421e3%3A0xca083fbd8018ac8d!2sSwamiworld!5e0!3m2!1sen!2sin!4v1676369870255!5m2!1sen!2sin" width={600} height={300}></iframe>
      </div>
      <section class="py-10 xl:py-16">
        <div class="container mx-auto px-4">
          <div class="scrollspy-container contact-cards">
            <h4 class="text-primary font-bold text-xl mb-5">Our Offices</h4>
            <div class="flex flex-wrap -mx-4 gap-y-8">						<div class="w-full md:w-1/2 xl:w-1/3 px-4">
              <div class="h-full tour_bg-white border rounded-lg p-5 card xl:text-sm">
                <div class="card-header pb-2 border-b">
                  <h6 class="text-lg font-semibold">Head Office</h6>
                </div>
                <div class="pt-4">
                  <div class="address cc-block">
                    <strong>Swami's World</strong><br />
                    432 A Narayan Peth, Patrya Maruti Housing Society Pune – 411030 </div>
                  <div class="phone cc-block">

                    <a class="inline-block whitespace-nowrap" href="tel:+91 8999638603">+91 8999638603</a>
                    <a class="inline-block whitespace-nowrap" href="tel:++91 9325586721">+91 9325586721</a>
                  </div>
                  <div class="email cc-block">
                    <a class="block bi-envelope-open" href="mailto:inquiry@swamiworld.in">inquiry@swamiworld.in</a>
                  </div>
                </div>
              </div>
            </div>

            </div>				</div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
