const TourDetails = [{
    "TourName": "Kashmir",
    "TourDate": "23/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [
        { "Title": "Day 1", "Location": "Departs from Pune by Flight to Srinagar (House Boat)", "Description": "Departure from Pune by Flight to Srinagar, Arrive Srinagar, Check inn to house boat and overnight at Srinagar, today we experience a stay at the floating boats, famously known as Kashmiri Houseboats which are designed with the local interiors." },
        { "Title": "Day 2", "Location": "Srinagar (House Boat)", "Description": "Today we enjoy a 250 steps trek to Shankaracharya Hill followed by the iconic Shikara ride at Dal Lake. Later visit the Char Chinar Island and click photographs in colourful Kashmiri outfits. Then we enjoy Nature Time and Photography tour at the lush green Kashmiri gardens – Shalimar, Chashme shahi, Nishat Bagh, and Tulip Garden and later we have free time for Shopping. And overnight at Srinagar." },
        { "Title": "Day 3", "Location": "Srinagar – Gulmarg – Srinagar (Hotel)", "Description": "After breakfast we enjoy an excursion to Gulmarg – ‘Meadow of Flowers’. We enjoy the famous Gondola ride (one of the highest in the world) to the second phase Apharwat point. We later enjoy, play and have fun time in Snow, also one can take up skiing and snowboarding activities, And overnight at Srinagar." },
        { "Title": "Day 4", "Location": "Srinagar – Sonmarg – Srinagar (Hotel)", "Description": "Morning after breakfast we proceed to Sonmarg – The meadow of gold. It mesmerizes tourists with its natural beauty. Here we enjoy horse riding, fishing and skiing, return back to Srinagar and overnight at Srinagar." },
        { "Title": "Day 5", "Location": "Srinagar – Pehelgam", "Description": "Morning after breakfast we proceed to Pahalgam. En-route we view the Saffron fields at Pampore. Then we take a photo stop at Apple Valley and Walnut Plantations In the evening and overnight at Pehelgam." },
        { "Title": "Day 6", "Location": "Pehelgam – Srinagar (Hotel)", "Description": "Today we enjoy a Walk & Click tour of Aru valley, Photo stop at Betaab valley. Later we spend some time playing in the snow at Chandawari. In the evening we have free time to stroll at the mall road of Pahelgam, and after lunch proceeds to Srinagar and overnight at Srinagar." },
        { "Title": "Day 7", "Location": "Srinagar – Pune", "Description": "Departs from Srinagar Airport by our Scheduled flight to Pune, Arrive Pune and Tour Concludes" },
    ],
    "TourCost": "55,500/-",
    "TourDays": "7",
    "TourId": "0",
    "BannerImage": "/images/kerala1.jpg",
    "TileImage": "/images/Alleppey.jpg",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"1"
    }]
},
{
    "TourName": "Rajastnan",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Pune – Ahmedabad – Mount Abu", "Description": "Departure from Pune by Air arrive Ahmedabad and proceed to Mount Abu, arrive Mount Abu, Check inn to Hotel, in the evening we visit Nakki Lake. Situated at a height of 1200 m above sea level, Nakki Lake is one of the largest man-made lakes in India. Enjoy a boat ride (on own) on the lake, later we enjoy amazing sunset at Sunset point and overnight at Mount Abu." },
    { "Title": "Day 2", "Location": "Mount Abu – Jodhpur", "Description": "After breakfast we explore Mount Abu by visiting Prajapita Bramhakumari Ahram and Dilwada Temples and proceed to Jodhpur – known as Blue city of India, upon arrival we visit Ummed Bhavan Palace Musuem – almost 75 years old architectural marvel now turned into museum showcasing artistic murals, opulent miniature paintings, vintage clocks and unusual household paraphernalia, In the evening, you have free time for shopping Ghantaghar Market and overnight at Jodhpur." },
    { "Title": "Day 3", "Location": "Jodhpur – Jaisalmer", "Description": "Morning after breakfast we visit the Mehrangarh Fort – which stands four hundred feet above the skyline of Jodhpur. The fort is burnished in Red Sandstone and its colossal structure is surely impressive in all manner. Here we will see Moti Mahal – largest room in fort whose ceiling is decorated with Gold Leaves, Sea Shells and Mirrors, Phool Mahal – Pleasure dome of the Maharajas, Sheesh Mahal – Decorative mirror palace with brightly coloured paintings, Silah Khana – place where weapons were stored, Daulat Khana – treasure vault and Rang Mahal. Later we visit Jaswant Thada – a royal cenotaph built in the white marble also known as Taj Mahal of Marwad and after Lunch proceeds to Jaisalmer, and overnight at Jaisalmer." },
    { "Title": "Day 4", "Location": "Jaisalmer – Sam Desert", "Description": "Today we explore Golden City of India – Jaisalmer. We visit Golden Fort – a UNESCO world heritage site and    one of the very few living forts in the world, as nearly one fourth the population of the city still resides within the Fort followed by visit to Jain Mandir – craved in yellow sandstones dedicated to Jain hermits known as Tirthankars. Later we visit Patwon ki Haweli Museum – Palace built in early 1800s, now a museum featuring intricate carvings, furniture and artwork, after lunch at around 02:00 PM, We proceed to famous Sam Sand Dunes. Upon arrival we experience thrilling Dune Bashing over the golden sands of Jaisalmer. In the evening we get an opportunity to witness the spectacular beauty of sandy terrain by taking joyful Camel Ride and view Red Orange colour splash of sunset on horizon of desert. Later we enjoy a traditional Rajasthani Folk Dance with Rajasthani traditional dinner and overnight at Tent in Same Desert." },
    { "Title": "Day 5", "Location": "Sam Sand Desert – Bikaner", "Description": "After breakfast proceed to Bikaner, arrive Bikaner we proceed to Bikaner – former capital of princely state of Bikaner and known worldwide for its savoury snacks Bikaneri Bhujia. Upon arrival we visit the Karani Mata Temple – a Hindu temple dedicated to Karani Mata and famous for the approximately 25,000 black rats that live and are revered in the temple and then we explore the Junagarh Fort – an impressive fort standing with all its regal glory as an epitome of architectural brilliance. The dazzling and exquisite structures of this fort, balconies, gardens and kiosks etc. depicts a composite architectural style influenced by cultural differences of Indian and foreign inspirations. Here we see Anup Mahal, Gaj Mandir, Sheesh Mahal and Prachina Museum and Sadual Museum overnight at Bikaner." },
    { "Title": "Day 6", "Location": "Bikaner – Jaipur", "Description": "After Breakfast proceed to Jaipur, arrive Jaipur, in the evening free for shopping in local market and overnight at Jaipur." },
    { "Title": "Day 7", "Location": "Jaipur", "Description": "Today after breakfast, we visit the Pink City, we visit Amer Fort – known for its Mughal influenced artistic style later visit Hawa Mahal – the palace of winds. En-route we visit City Palace – the royal residence, known for its architectural blend of Indian, Mughal, Rajput and European styles. Followed by visit to Jantar Mantar – A UNESCO World Heritage site also an astronomical marvel, later we visit Birla Temple – originally known as Lakshmi Narayan Temple dedicated to Lord Vishnu and Goddess Laxmi, In the evening, you have free time for shopping at Bapu Market and overnight at Jaipur." },
    { "Title": "Day 8", "Location": "Jaipur – Pushkar", "Description": "After breakfast proceed to Pushkar, on arrival we visit Brahma Mandir – one of very few existing temples dedicated to the Hindu creator-god Brahma built in 14th century, followed by visit to Pushkar Tirth and overnight at Pushkar." },
    { "Title": "Day 9", "Location": "Pushkar – Chittorgarh – Haldighati – Udaipur", "Description": "Morning after breakfast proceeds to visit Chittorgarh Fort – a UNESCO World Heritage Site and a symbol of bravery. Here we will see Ranakumbha Palace – where founder of Udaipur Maharana Udai Singh was born, Meera Mandir – was once the home of famous bhakti poetess Meerabai, Vijay Stambha – victory monument constructed by Mewar king to commemorate victory over Mahmud Khilji and Queen Padmini’s Palace – located amidst water bodies later proceed to Udaipur en-route we visit Haldighati Museum arrive Udaipur and overnight at Udaipur." },
    { "Title": "Day 10", "Location": "Udaipur", "Description": "Today we visit Lake city of India Known as Udaipur, we visit Saheliyon Ki Bari – also known as the Garden of Maidens, is one such marvel of nature created by man for a woman later we visit Maharana Pratap Smarak –followed by Fatehsagar lake, is a historic site that is dedicated to the great Maharana Pratap Singh and his loyal horse Chetak. Later we visit City Palace – an architectural marvel and largest royal palace complex in Rajasthan built on banks of Lake Pichola. In the evening we enjoy a traditional music and dance show at Bhartiya lok kala mandal and overnight at Udaipur." },
    { "Title": "Day 4", "Location": " Udaipur – Ahmedabad – Home town", "Description": "Today after breakfast Check out from hotel and proceeds Ahmedabad, arrive and departure by our scheduled to Pune and tour concludes." },
    ],

    "TourCost": "55,500/-",
    "TourDays": "11",
    "TourId": "2",
    "BannerImage": "/images/Rajasthan.jpg",
    "TileImage": "/images/Rajasthan.jpg",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
    "Id":"2"
    }]
},
{
    "TourName": "Goa",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Arrive Goa", "Description": "Arrive Goa at your scheduled time, Check inn to hotel and the evening free for leisure and overnight at Goa." },
    { "Title": "Day 2", "Location": "North Goa Tour", "Description": "we will enjoy North Goa Tour which includes Shanta Durga Temple, Shree Mangeshi Temple, Old Goa Church, Dona Paula, leisure at Kolva Beach and overnight at Goa." },
    { "Title": "Day 3", "Location": "South Goa Tour", "Description": "Today we enjoy South Goa Tour which includes Fort Aguada, Sinquerim Beach, Calangute Beach, Baga Beach, Anjuna, Vagator Beach, Chapora Fort and overnight at Goa." },
    { "Title": "Day 4", "Location": "Goa – Pune", "Description": "After breakfast proceed to Pune – and Tour concludes." }],
    "TourCost": "28,000/-",
    "TourDays": "4",
    "BannerImage": "/images/goa1.avif",
    "TileImage": "/images/goa1.avif",
    "TourId": "3",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"3"
    }]
},
{
    "TourName": "Orissa",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Bhubaneshwar", "Description": "Arrive Bhubaneshwar Airport / Railway station and proceed to Hotel, check inn to hotel and overnight at Bhubaneshwar,(Our Representative will be present and welcome’s you at Bhubaneshwar Railway station)" },
    { "Title": "Day 2", "Location": "Bhubaneshwar", "Description": "Morning after breakfast at 08:30 AM, proceeds to visit Chilika Lake (UNESCO World Heritage site) – one of the most important wetlands in the world because it is home to a phenomenal variety of birds. We visit Satpada island – a major attraction for spotted Irrawaddy dolphins and Sea Mouth – the Island where Chilka lake meets the Bay of Bengal, return back to Puri and overnight at Puri." },
    { "Title": "Day 3", "Location": "Bhubaneshwar – Konark – Puri", "Description": "Morning after breakfast at 08:30 AM, proceeds to visit Pipli – Handicraft village, later we visit Konark Sun temple – an architectural marvel which is an UNESCO World Heritage Site and Arka Khetra Sun Temple Interpretation Centre later arrive Jagannath Puri and we visit Jagannath Temple – one of the major pilgrimage centres in India followed by a visit to Chandrabhaga Beach. In the evening you can enjoy very popular sea beach and overnight at Puri." },
    { "Title": "Day 4", "Location": "Puri – Chilika Lake – Puri", "Description": "Morning after breakfast at 08:30 AM, proceeds to visit Chilika Lake (UNESCO World Heritage site) – one of the most important wetlands in the world because it is home to a phenomenal variety of birds. We visit Satpada island – a major attraction for spotted Irrawaddy dolphins and Sea Mouth – the Island where Chilka lake meets the Bay of Bengal, return back to Puri and overnight at Puri." },
    { "Title": "Day 5", "Location": "Puri – Bhubaneshwar & Departure to Pune", "Description": "Morning after breakfast proceeds to Bhubaneshwar Airport / Railway station and proceed to Pune and tour concludes." }
    ],
    "TourCost": "28,000/-",
    "TourDays": "5",
    "TourId": "3",
    "BannerImage": "/images/dilip-poddar-ObfNPIX6TuI-unsplash.jpg",
    "TileImage": "/images/purijagannath.jpg",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"4"
    }]
},
{
    "TourName": "Ahmedabad",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Departs from Pune by Train to Ahmadabad", "Description": "Departure from Pune by Duronto Express at evening at 09:30 PM(Note – Please reach Railway Station 01 hours before scheduled Train departure time)" },
    { "Title": "Day 2", "Location": "Ahmadabad", "Description": "Arrive Ahmedabad at Morning at 06:20 AM, check inn to hotel and after fresh – up and breakfast proceeds to visit Modhera Sun Temple – one of the most exquisitely-sculpted temples in India, the Sun Temple in Modhera Gujarat is a prominent shrine dedicated to God Sun, later proceeds to visit Rani ki Vav – epitomises the peak of Maru-Gujarat architectural style. The 900-year-old structure listed as a UNESCO World Heritage Site is displayed prominently in the new ₹ 100 currency note issued by the Reserve Bank of India, return Back to Ahmadabad and overnight. (B-L-D)" },
    { "Title": "Day 3", "Location": "Ahmadabad – Statue of Unity – Kevadia", "Description": "Morning after breakfast at 08:00 AM, proceeds to visit the iconic Statue of Unity – tallest statue in the world dedicated to Indian statesman and independence activist Sardar Vallabhbhai Patel. We enjoy the views of the surrounding from the viewing gallery, Valley of Flowers – spread across 24 acres of land and is a haven for colourful flowering plants, Sardar Sarovar Dam and then we visit Cactus Garden – unique botanical garden, we then visit the state-of-art zoological park with unique collection of exotic animals and birds from the various biogeographic regions. Here we go for a Jungle Safari – to spot animals, reptiles and birds, evening enjoy the spectacular light and sound show and overnight at Kevadia Tent city. (B-L-D)" },
    { "Title": "Day 4", "Location": "Kevadia – Nilkanth Dham – Poicha – Baroda", "Description": "Morning after breakfast at 09:00 AM, proceeds to visit Poicha – Nilkanth Dham – It is beautiful swaminarayan temple constructed in large area and one of the most amazing pilgrimage attracts people around Gujarat. Then we visit Sahjanand Universe – has 7 Parts. In this Seven Parts all devout and Indian Culture Statue and Ramayana, Mahabharata, Krishna Leela, and many more like naturally beauty. At Sahjanand universe complex you can see attractive main gate, Nilkanth hardy Kamal, more than One thousand and one hundred idols related Indian culture and Hindu pilgrimage, science city, activity park, water park, boat riding, bhul bhulaiya, horror house, yampuri darshan, lights and sound shows in 3D and theaters later we visit Garudeshwar – Tembhe swami Samadhi and Dattaguru temple and proceed to Baroda, arrive Baroda, evening free for shopping and overnight at Baroda. (B-L-D)" },
    { "Title": "Day 5", "Location": "Baroda Local sightseeing – Ahmadabad – Departure by Train", "Description": "Today after breakfast we visit Laxmi Vilas palace – Still the residence of Vadodara’s royal family, Laxmi Vilas was built in full-throttle 19th-century Indo-Saracenic flourish at a cost of Rs 6 million. Built over 500 acres, this is the largest private home built till date and four times the size of Buckingham Palace. The most impressive Raj-era palace in Gujarat, its elaborate interiors boast well-maintained mosaics, chandeliers and artworks, as well as a highly impressive collection of weaponry and art. Paintings by the famous artist, Raja Ravi Verma later we visit Sayaji Baug – Art Gallery, Museum and Zoo and at 06:30 PM – proceeds to Ahmedabad, arrive Ahmadabad around 09:30 PM and Night at 10:30 PM board the Duronto Express Train to Pune. (B-L-D)" },
    { "Title": "Day 6", "Location": "Arrive Pune", "Description": "Arrive Pune in the morning at 07:00 AM, and Tour Concludes \n\n (B – Breakfast,        L – Lunch,     D – Dinner)" }
    ],
    "TourCost": "22,000/-",
    "TourDays": "6",
    "TourId": "3",
    "BannerImage": "/images/statueofunity2.jpeg",
    "TileImage": "/images/yog_7506.jpg",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"5"
    }]
},
{
    "TourName": "Vientam Combodia",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Pune – Mumbai – Phnom Penh – Siem Reap", "Description": "Transfer to Mumbai International Airport and departs from Mumbai to Phnom Penh, on arrival meet and greet to our Local representative and proceed to Siem Reap – a resort town in north-western Cambodia, is the gateway to the ruins of Angkor, the seat of the Khmer kingdom from the 9th–15th centuries. It’s a jewel of South East Asia that leaves traveller stunned, and overnight at Siem Reap." },
    { "Title": "Day 2", "Location": "Angkor Wat", "Description": "Today we explore South Gate – the ancient capital of Angkor Thom (12th c.), Bayon temple (54 Unique towers decorated with 200 smiling faces), the royal enclosure, Phimeanakas, the Elephants Terrace and the Terrace of the Leper King. Later we visit Ta Prohm Temple and Angkor Wat – A UNESCO World Heritage Site. This temple complex covers 81 hectares and is comparable in size to the Imperial Palace in Beijing. Angkor Wat’s five towers symbolize Meru’s five peaks – the enclosed wall represents the mountains on the edge of the world. In the evening, we take a traditional Cambodian Massage. In the evening, we enjoy the traditional Apsara Dance Show with dinner and overnight at Siem Reap." },
    { "Title": "Day 3", "Location": "Siem Reap – Hanoi", "Description": "Today after breakfast proceed to Siem Reap International Airport and board to Hanoi – Vietnam’s capital city for over 1000 years. Upon arrival, we explore Hanoi city, we visit the Temple of Literature – which is well known as the first university of Vietnam built in the 11th century, One Pillar Pagoda – whose origins date back to the foundation of the city and Ho Chi Minh Complex. The Ho Chi Minh complex comprises of the Mausoleum of the nation’s founder – Ho Chi Minh." },
    { "Title": "Day 4", "Location": "Hanoi – Halong Bay Cruise", "Description": "Today we proceed to Halong – a UNESCO World Heritage site. The name Halong is derived from the Sino-Vietnamese word, meaning ‘Descending Dragon’. Here we enjoy a fantastic cruise sailing in Halong bay and we enjoy a fantastic cruise sailing in Halong bay and view Dark and Light cave. We also pass by Dinh Huong Island and Ga Choi Islands. Today we enjoy overnight stay on cruise amidst beautiful landscapes of Halong Bay." },
    { "Title": "Day 5", "Location": "Halong Bay Cruise – Hanoi – Da Nang", "Description": "Enjoy the fresh morning in the middle of the bay while cruising through the magnificent rock formations and back towards port. After disembarking we proceed back to Hanoi. Upon arrival we visit the Temple of Literature – which is well known as the first university of Vietnam built in the 11th century. Later we fly to Da Nang – a coastal city known for its sandy beaches and history as a French colonial port. Upon arrival, we see Dragon Bridge and other illuminated bridges on the river Han." },
    { "Title": "Day 6", "Location": "Da Nang – Ba Na Hills – Da Nang", "Description": "Today we proceed to Sun World Ba Na Hills. We drive on one of the most beautiful roads of the city and arrive at the cable car station at the foot of Ba Na Hills. Enjoy the cable car ride offering spectacular views of Da Nang and the surrounding forests. We first visit Golden Bridge – the Bridge has a design of it being held in two giant hands and it offers some spectacular views of the Vietnamese countryside. We also enjoy the other attractions such as Le Jardin, Wax Museum, French Village, Pagodas and Alpine Coaster. In the evening we proceed to Hoi An – an ancient town which was declared as World Heritage Site by UNESCO is now a peaceful town of exceptionally well-preserved architecture, lifestyles and traditions. We take a walking city tour and discover a 400-year-old Covered Bridge, Phung Hung Ancient House, Fujian (assembly hall). See a different side of Hoi An when all the roads, houses, restaurants and cafes are illuminated by thousands of lanterns, creating a magical glow. We then take a Lantern Boat Ride on the Hoai river and witness the town’s architectural wonders come to life under the shimmering lantern lights and overnight at Da Nang" },
    { "Title": "Day 7", "Location": "Da Nang – Ho Chi Minh", "Description": "Today after breakfast board the flight to Ho Chi Minh, on arrival we explore Ho Chi Minh city by visiting Dame Cathedral, the Old Sai Gon Post Office, former US Embassy and Cho Lon including Thien Hau Pagoda. We then visit the War Crime Museum which features a comprehensive collection of the machinery, weapons, photos, and the documentation of Vietnam’s war with both the French and the Americans, Reunification Palace which was the former residence of the President of South Vietnam until the end of April 1975 and overnight at Ho Chi Minh." },
    { "Title": "Day 8", "Location": "Ho Chi Minh – Cu Chi Tunnel – Ho Chi Minh", "Description": "Today early morning, we proceed to Cu Chi Tunnels, Cu Chi was an important base during the American War, because of its strategic location. The Vietcong built a 200km long network of tunnels connecting command posts, hospitals, shelters and the weapon factories. Dug out of hard laterite by hand tools without the use of cement, this amazing network was never discovered. Later we return to Ho Chi Minh city and overnight." },
    { "Title": "Day 9", "Location": "Ho Chi Minh – Mumbai – Pune", "Description": "After breakfast proceed to Ho Chi Minh International Airport and board the flight at Mumbai, Arrive Mumbai and proceed to Pune and Tour Concludes" }],
    "TourCost": "1,85,000/-",
    "TourDays": "9",
    "TourId": "3",
    "BannerImage": "/images/vietnam1.jpg",
    "TileImage": "./images/vietnam1.jpg",
    "Category": "International",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"6"
    }]
},
{
    "TourName": "Singapore Malaysia",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Departs from Mumbai – Singapore", "Description": "Departure from Mumbai by our scheduled flight, Arrive Singapore, on arrival check inn to hotel and in the evening proceed to Night Jungle Safari to experience a close encounter with the wild animals. We take a Tram ride which takes us through the 7 geographical zones, you can enjoy the fire show and return back to Singapore and overnight at Singapore." },
    { "Title": "Day 2", "Location": "Singapore", "Description": "Today you will experience Universal studios which offers excitement, thrills and unlimited entertainment, Experience thrilling shows and attractions like Light Camera Action, Transformer, Accelerator ride, Revenge of the Mummy, Water world, Jurassic Park, Madagascar – A Crate Adventure, Donkey live, Shrek 4D adventure, Flying Dragon, etc and overnight at Singapore." },
    { "Title": "Day 3", "Location": "Singapore", "Description": "Today we explore Singapore city, we visit Suntec City, Fountain of Wealth, City Hall, Orchard Road, Swissotel the Stamford – the tallest hotel in Singapore, Parliament House, The Esplanade and Merlion – Symbol of Singapore. Later we enjoy a Cable Car ride from Mt. Faber to Sentosa Island. Here we enjoy a ride in the highest open-air gondola called the Sky Helix. We also visit Images of Singapore, Madame Tussauds wax museum, later we visit Asia’s biggest SEA Aquarium which houses the rare species of the marine world and overnight at Singapore" },
    { "Title": "Day 4", "Location": "Singapore – Kuala Lumpur, Malaysia", "Description": "Today proceed to Malaysia by Coach, on arrival check inn to Hotel, later we visit King’s Palace, Dataran Merdeka Square, National Mosque, Cricket Field and High Court Building, photo stop at the backdrop of Malaysia’s famous landmark – Petronas Twin Towers, Observatory Deck of the K. L. Tower and overnight at Kuala Lumpur." },
    { "Title": "Day 5", "Location": "Genting Highland, Malaysia", "Description": "Today we proceed to Genting Highlands, En-route we visit Batu caves and Murugan Temple, later arrive Genting here we enjoy one of the longest and fastest Cable Cars of South East Asia. Upon arrival, we explore this world-famous entertainment capital. Here we visit Casino, First World hotel and optional theme Park, return back to Kuala Lumpur and overnight." },
    { "Title": "Day 6", "Location": "Kuala Lumpur – Pune", "Description": "Today early morning, proceed to Kuala Lumpur International Airport and board the flight to Pune, Arrive Pune and tour concludes." }],
    "TourCost": "65,400/-",
    "TourDays": "6",
    "TourId": "3",
    "BannerImage": "/images/vietnam1.jpg",
    "TileImage": "/images/vietnam1.jpg",
    "Category": "International",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"7"
    }]
},
{
    "TourName": "Singapore Bali",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Departs from Mumbai – Singapore", "Description": "Departure from Mumbai by our scheduled flight, Arrive Singapore, on arrival check inn to hotel and in the evening proceed to Night Jungle Safari to experience a close encounter with the wild animals. We take a Tram ride which takes us through the 7 geographical zones, you can enjoy the fire show and return back to Singapore and overnight at Singapore." },
    { "Title": "Day 2", "Location": "Singapore", "Description": "Today you will experience Universal studios which offers excitement, thrills and unlimited entertainment, Experience thrilling shows and attractions like Light Camera Action, Transformer, Accelerator ride, Revenge of the Mummy, Water world, Jurassic Park, Madagascar – A Crate Adventure, Donkey live, Shrek 4D adventure, Flying Dragon, etc and overnight at Singapore." },
    { "Title": "Day 3", "Location": "Singapore", "Description": "Today we explore Singapore city, we visit Suntec City, Fountain of Wealth, City Hall, Orchard Road, Swissotel the Stamford – the tallest hotel in Singapore, Parliament House, The Esplanade and Merlion – Symbol of Singapore. Later we enjoy a Cable Car ride from Mt. Faber to Sentosa Island. Here we enjoy a ride in the highest open-air gondola called the Sky Helix. We also visit Images of Singapore, Madame Tussauds wax museum, later we visit Asia’s biggest SEA Aquarium which houses the rare species of the marine world and overnight at Singapore" },
    { "Title": "Day 4", "Location": "Singapore – Bali", "Description": "Today as per our scheduled flight, proceeds Bali, Arrive Denpasar – the capital of one of the most exotic destinations in the world. Upon arrival at the airport, complete your immigration formalities. Outside the arrival hall, you will be met by our local representative who will welcome you and take you to your hotel, overnight at the hotel in Bali." },
    { "Title": "Day 5", "Location": "Full day Ubud Monkey Forest, Kintamani tour", "Description": "After breakfast we proceed to Ubud Monkey Forest and Kintamani. The highlight of this tour is the magnificent view of Mount and Lake Batur with the smoky Agung Volcano in the background. En route stop at Ubud Monkey Forest, The holy spring of TirtaEmpul at Tampak Siring, Coffee and Cacao Plantation at Madu Pertiwi Agro Tourism, Overnight at the hotel in Bali." },
    { "Title": "Day 6", "Location": "Full day Bedugul- Tanah Lot Sunset tour", "Description": "Today you visit to Bedugul tour (Ulundanu Temple). This temple is located on Beraton Lake in Bedugul Area, in thenorthwestern part of Bali. The weather in this area is so cool and fresh, with the beautiful scenery surrounding this area. Ulundanu Temple is dedicated to the Godess of serene Lake Beratan, as a manifestation of Brahman as Wisnu, the preserver. Then drive to Tanah Lot Sunset tour . Tanah Lot Temple is one of the most popular places of interest in Bali. It is also called Tanah Let which means ancient land and also Tanah Lod, which means the land to the south. The temple Pura Tanah Lot, simple in its construction, is dramatic in its ocean-front location and is one of the main temples in the worship of Balinese Gods, Overnight at the hotel in Bali." },
    { "Title": "Day 7", "Location": "Uluwatu Temple Tour", "Description": "After Breakfast at hotel we enjoy the Uluwatu Temple tour, we also visit the Kecak Dance Tour and Overnight at the hotel in Bali." },
    { "Title": "Day 8", "Location": "Bali – Singapore – Mumbai", "Description": "After Breakfast proceed to Denpasar International Airport and proceed to Mumbai via Singapore, Arrive Mumbai and Tour Concludes." },
    ],
    "TourCost": "95,300/-",
    "TourDays": "8",
    "TourId": "3",
    "BannerImage": "/images/vietnam1.jpg",
    "TileImage": "/images/vietnam1.jpg",
    "Category": "International",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"8"
    }]
},
{
    "TourName": "Sri lanka",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Pune – Mumbai – Colombo – Kandy   ", "Description": "Transfer from Pune by our private Bus to Mumbai International Airport and departs from Mumbai to Colombo Commercial capital, largest city of Sri Lanka and has long history as a port on ancient east – west trade route. Upon arrival, we will complete airport formalities and proceed to Kandy – a city located in the heart of Sri Lanka and famous as the Tea Capital of the country. En route we visit Pinnawala Elephant Orphanage – an orphanage, nursery and captive breeding facility for wild Asian elephants. In the evening free for leisure at one of the most beautiful cities in Asia – Kandy and overnight at Kandy." },
    { "Title": "Day 2", "Location": "Kandy", "Description": "Today we explore the city of Kandy, we visit Temple of the Tooth Relic – a sacred temple situated within the Kandy Royal Palace Complex which houses the tooth relic of the Buddha, Kandy Lake – a picturesque body of water situated in front of the Tooth Relic Temple, is also famously known as the “Sea of Milk.”, Kandy Town, Arts and Crafts centre, Gems Gallery and Lapidary. Later we explore Peradeniya Botanical Gardens, which are renowned for their extensive collection of various orchid species. The garden features over 4,000 plant species, encompassing a wide range of plants such as spices, medicinal herbs, and palm trees. In the evening, we will enjoy a traditional Sri Lankan Dance show and overnight at Kandy." },
    { "Title": "Day 3", "Location": "Kandy – Nuwara Eliya", "Description": "Today after breakfast proceed to Nuwara Eliya En-Route we visit Anjaneyar (Hanuman) temple in Ramboda – according to ancient, this is the place where Lord Rama’s forces gathered to prepare for the battle against King Ravana, followed by a visit to the Tea Factory. Later we proceed to Nuwara Eliya – a picturesque town located in highlands of Sri Lanka and known as country’s coolest place. Upon arrival, we visit Seetha Amman Temple – according to legends this place is believed to be the site where Sita was held captive by King Ravana. Later we visit Hakgala Botanical Gardens (Ashok Vatika) – It is said that Demon King Ravana offered this area to Maa Sita as a pleasure garden. Hakgala Botanical Garden is Sri Lanka’s second-largest botanical garden, boasting an extensive collection of over 10,000 plant species. It is particularly renowned for its diverse assortment of orchids and roses. In the evening, we have free time for relaxing and exploring the surroundings and overnight at Nuwara Eliya." },
    { "Title": "Day 4", "Location": "Nuwara Eliya – Bentota", "Description": "Today we proceed to Bentota – known as resort town on Sri Lanka’s southwest coast, graced by palm trees and a serene atmosphere and is also a popular weekend getaway. Upon arrival we have free time for shopping at Ambalangoda which is famous for antique shops with unique fancy jewellery items, evening free for leisure at Sea Beach and overnight at Bentota." },
    { "Title": "Day 6", "Location": "Bentota – Galle – Bentota", "Description": "Today we enjoy Bentota River Safari and see water monitors, river birds, and maybe even rare crocodiles in their natural habitats. Later we visit Kosgoda Turtle Hatchery – an institute dedicated to the protection and safeguarding sea turtles, further proceed to Galle – a historic coastal city known for its well-preserved Dutch colonial architecture and rich cultural heritage. Enroute we visit Ariapala Mask Museum – a museum featuring a collection of traditional masks and related artifacts, along with a workshop, store, and library. Upon arrival in Galle, we explore Galle city, we visit Dutch Fort – one of the most extraordinary historical and archaeological sites of the world, Galle Lighthouse – the oldest lighthouse in Galle, Japanese Peace Pagoda – one of the three Buddhist stupas in Sri Lanka. Further we relax at Unawatuna Beach – a banana shaped beach, with golden sand, sparkling water, and lush palm trees. Later we return back to Bentota and overnight." },
    { "Title": "Day 7", "Location": "Bentota – Colombo City Tour – Mumbai", "Description": "Today we proceed to Colombo. Upon arrival we explore the city of Colombo, we visit Kelaniya Temple – the shrine dedicated to King Ravana’s brother – Bibhishana, we see the Buddhist temple, 100 years old Clock tower, the Bandaranaike Memorial International Conference Hall, British built colonial buildings. Later we have free time for shopping at House of Fashion mall, and proceed to Colombo International Airport and board the flight to Mumbai. Arrive Mumbai and proceed to Pune with lots of sweet memories and tour concludes." }
    ],
    "TourCost": "76,000/-",
    "TourDays": "6",
    "TourId": "3",
    "BannerImage": "/images/sri-lanka-wooden-sign.jpg",
    "TileImage": "/images/sri-lanka-wooden-sign.jpg",
    "Category": "International",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"9"
    }]
},
{
    "TourName": "Nepal",
    "TourDate": "28/8/204",
    "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
    "Itinerary": [{ "Title": "Day 1", "Location": "Pune – Mumbai – Kathmandu", "Description": "Transfer to Mumbai from Pune and Departure from by Flight to Kathmandu, Arrive Kathmandu, Welcome to Nepal, evening free for leisure and overnight at Kathmandu." },
    { "Title": "Day 2", "Location": "Kathmandu", "Description": "Today we explore Kathmandu city, we visit Pashupatinath Temple – One of the 12 Jyotirlingas and the only one to be located outside India, followed by a visit to the monuments of Kathmandu like, Budhanilkantha also known as “Sleeping Vishnu.” Further we visit Swayambhunath Stupa – one of the glorious stupas in the world whose establishment is linked to the creation of Kathmandu valley. In the evening, we have free time for shopping in Vishal Bazar and local market and overnight at Kathmandu" },
    { "Title": "Day 3", "Location": "Kathmandu – Pokhara", "Description": "Today after breakfast proceed to Pokhara, enroute we enjoy a scenic ropeway ride to visit the Manakamana Devi Temple – a sacred place of the Hindu Goddess Bhagwati, an incarnation of Parvati. Later we proceed to Pokhara, upon arrival check inn to Hotel and then we enjoy a pleasant boat ride in Phewa Lake – reflecting the panoramic views of Mt. Fishtail, followed by a visit to Barahi Temple – located on an island in Phewa Lake and overnight at Pokhara." },
    { "Title": "Day 4", "Location": "Pokhara – Mukti Nath", "Description": "Today after breakfast proceed to Mukti Nath, Arrive Mukti Nath and evening free for leisure and overnight at Mukti Nath." },
    { "Title": "Day 5", "Location": "Mukti Nath – Pokhara ", "Description": "Today we have to walk up for 30 minutes to reach the temple from the Jeep parking. You can hire a pony on your own which costs INR 700 to 800 for a two-way ride. After visiting the temple, complete your Darshan and Pooja return back to Jeep Parking and proceeds to Pokhara, arrive Pokhara in the evening and overnight at Pokhara." },
    { "Title": "Day 6", "Location": "Pokhara – Chitwan", "Description": "Morning after breakfast we explore Pokhara city, we visit Devi’s Fall and Gupteshwar Caves. Further we visit Vindyabasini Temple, Later proceed to Chitwan – the first National Park of Nepal and a UNESCO world heritage site. In the evening we enjoy the Folk dance of Nepal, and overnight at Chitwan." },
    { "Title": "Day 7", "Location": "Chitwan – Kathmandu", "Description": "Today early morning we visit Chitwan National Park – well known for its ‘One Horned Rhinoceros’ and Elephant Farm. We experience thrilling activities like Elephant or Jeep Safari, later proceed to Kathmandu and overnight at Kathmandu" },
    { "Title": "Day 8", "Location": "Kathmandu – Mumbai – Pune", "Description": "Today after breakfast proceeds to Kathmandu International and Departure by Flight to Mumbai, arrive Mumbai and proceed to Pune with lots of sweet memories Tour Concludes." }
    ],
    "TourCost": "65,000/-",
    "TourDays": "8",
    "TourId": "3",
    "BannerImage": "/images/Bhutan.jpg",
    "TileImage": "/images/Namdroling-Monastery (2).jpg",
    "Category": "Domestic",
    "TripInlcude": [
        { "Title": "", "Value": "Accommodation in Deluxe hotels on twin sharing basis" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },

    ],
    "TripExclude": [
        { "Title": "", "Value": "Any extra cost incurred on behalf of an individual due to illness, accident, hospitalisation, or any personal emergency" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
        { "Title": "", "Value": "" },
    ],
    "PriceDetails":[ {
        "Title":"Single occupancy",
        "Price":"55,500.00",
        "AirFare":"0.00",
        "NetPrice":"55,500.00",
        "Id":"10"
    }]
}
];

export default TourDetails;