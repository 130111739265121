import React from "react";
import aboutimage from "../assets/about_hero_img.jpg";
import "../styles/About.css";
function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${aboutimage})` }}
      ></div>
      <div className="aboutBottom">
        <h1 style={{ paddingTop: "10px", paddingBottom: "60px" }}> Discover the world with us</h1>
        <div class="et_pb_column et_pb_column_4_4 et_pb_column_1    et_pb_css_mix_blend_mode_passthrough et-last-child">


          <div class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light et_pb_text_align_center et_had_animation" >


            <div class="et_pb_text_inner">
              <p>Welcome to Swami World!</p>
              <p>We are a team of passionate travel experts who love to explore the world and help others do the same. Our mission is to create unforgettable travel experiences for our clients, from planning the perfect itinerary to providing on-the-ground support throughout your trip.</p>
              <p>At Swami World, we believe that travel has the power to transform lives. Whether you’re looking to relax on a beach, discover new cultures, or challenge yourself with an adventure, we can help you make it happen. Our team has years of experience in the travel industry, and we have built relationships with some of the best hotels, tour operators, and other travel providers around the world. This means that we can offer you exclusive deals and insider knowledge that you won’t find anywhere else.</p>
              <p>We are committed to providing personalized service and attention to detail for each and every client. We take the time to get to know you and your travel preferences so that we can create a customized itinerary that meets your needs and exceeds your expectations. And once you’re on the road, we’re always just a phone call or email away to answer any questions or assist with any issues that may arise.</p>
              <p>Thank you for considering Swami World for your next adventure. We can’t wait to help you create unforgettable memories that will last a lifetime.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
