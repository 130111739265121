import React from "react";
import '../styles/PopularTripsTile.css';
import calendarsvg from '../assets/calendar.svg';
import kerala1 from '../assets/kerala1.jpg';

function PupularTours({ img, title, link, TourData }) {
   
    return (

        <div className="relative rounded-xl overflow-hidden card">
            <div className="img-wrap">
                <a href={link} className="block"><img src={img} className="w-full h-[380px] object-cover" /></a>
            </div>
            <div href={link} className="absolute left-0 right-0 bottom-0 p-4 tour_bg-white rounded-t-3xl">
                <h5 className="font-semibold text-black text-lg">{title}</h5>
                <p>From: <span className="text-[#ff0000] font-semibold">{TourData.TourCost}</span></p>
                <div className="flex items-center justify-between bg-primary/[.05] rounded py-2 px-4 mt-4 text-sm">
                    <div className="flex items-center gap-x-2">
                        <img src={calendarsvg} alt="duration" width="16" height="16" loading="lazy" />
                        {TourData.TourDays} </div>
                    <div>
                        <a href={link} className="text-slate-800 font-semibold">Explore -</a>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PupularTours;
