import React from "react";
import "../styles/Tour.css";
import { TourList } from "../helpers/TourList";
import andaman from '../assets/andaman.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import varanasi from "../assets/varanasi.jpg";
import Rajasthan from "../assets/Rajasthan.jpg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
function Tour({ match,TourDetails }) {
    var currentTour = undefined;
    var filterTours = TourDetails.filter((element) => { return element.TourName.toLowerCase() === match.params.title });
    if (filterTours.length > 0) {
        currentTour = filterTours[0];
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "hsl(204.65deg 92.81% 27.25%)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    if (currentTour != undefined) {
        return (
            <div>
                <div className="tourHome" style={{ backgroundImage: `url(${currentTour.BannerImage})` }}>
                    <div className="TourheaderContainer">
                        <h1>{currentTour.TourName}</h1>
                    </div>
                </div>
                <div className="border-t border-t-[#eee] tour-tabs-wrap z-10">
                    <div className="container mx-auto px-4 margin-left9vh px-4-5">
                        <nav className="tour-tabs">
                            <ul className="flex gap-x-6">
                                <li><a href="#itenarary" className="active">Itinerary</a></li>
                                <li><a href="#location">Location</a></li>
                                <li><a href="#gallery">Gallery</a></li>
                                <li><a href="#pricing-details">Pricing Details</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <section className="bg-lightgray py-10 xl:py-16 tour-single-details">
                    <div className="lg:w-2/3 mx-auto margin-left15vh" >
                        <div className="flex flex-wrap -mx-4">
                            <Row>
                                <Col lg={8} md={12} sm={12}><div className="w-full lg:w-2/3 px-4 px-4-5">
                                    <div className="tour_bg-white itenarary" id="itenarary">
                                        <div className="py-3 px-4 border-b">
                                            <h3 className="text-xl xl:text-2xl font-bold align-left">Itinerary</h3>
                                        </div>
                                        {currentTour.Itinerary.map((element) => {
                                            return (<div className="day-info">
                                                <div className="mb-2 flex items-center gap-x-4 margin-top-2vh">
                                                    <span className="badge text-sm">{element.Title}</span>
                                                </div>
                                                <h6 className="mb-1 text-lg font-semibold itenararyHeader margin-left-2vh">{element.Location}</h6>
                                                <p className="itenararyDescription">{element.Description}</p>
                                            </div>);
                                        })}

                                    </div>
                                    <div className="location tour_bg-white mt-8" id="location">
                                        <div className="py-3 px-4 border-b border-b-[#eee]">
                                            <h3 className="align-left text-xl xl:text-2xl font-bold">Location</h3>
                                        </div>
                                        <div className="p-4">
                                            <iframe src={currentTour.TourLocation} width="600" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>							</div>
                                    </div>
                                    <div className="gallery tour_bg-white mt-8" id="gallery">
                                        <div className="py-3 px-4 border-b border-b-[#eee]">
                                            <h3 className="align-left text-xl xl:text-2xl font-bold">Gallery</h3>
                                        </div>
                                        <div className="p-4">
                                            <Row>
                                                <Col sm={12} md={4}><img src={varanasi}></img></Col>
                                                <Col sm={12} md={4}><img src={andaman}></img></Col>
                                                <Col sm={12} md={4}><img src={Rajasthan} ></img></Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div></Col>
                                <Col lg={4}>
                                    <div className="">
                                        <div className="mb-5 bgWhite priceBox">
                                            <span className=" block text-[#888] font-medium text-sm">Price Rs.</span>
                                            <span className=" price text-2xl text-primary font-semibold block">{currentTour.TourCost}</span>
                                        </div>
                                    </div>
                                    <div className="cost_includes tour_bg-white mt-8 acc" id="cost_includes">
                                        <div className="py-3 px-4 border-b border-b-[#eee] acc-title flex gap-x-6 justify-between items-center">
                                            <h4 className="text-lg font-semibold align-left">Cost Includes</h4>
                                        </div>
                                        <div className="p-4 acc-panel text-sm check-list align-left" style={{ display: "block" }}>
                                            <ul className="Omargin">
                                                {currentTour.TripInlcude.map((element) => {
                                                    return (<li>{element.Value}</li>)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="cost_excludes tour_bg-white mt-8 acc" id="cost_excludes">
                                        <div className="py-3 px-4 border-b border-b-[#eee] acc-title flex gap-x-6 justify-between items-center">
                                            <h4 className="text-lg font-semibold align-left">Cost Excludes</h4>
                                        </div>
                                        <div className="p-4 acc-panel text-sm uncheck-list align-left" style={{ display: "block" }}>
                                            <ul className="Omargin">
                                                {currentTour.TripExclude.map((element) => {
                                                    return (<li>{element.Value}</li>)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                        </div>
                        <div className="tour_bg-white pricing mt-8 lg:mt-0 margin-left3_4rem" id="pricing-details">
                            <div className="py-3 px-4 border-b border-b-[#eee]  align-left">
                                <h3 className="text-xl xl:text-2xl font-bold">Pricing</h3>
                            </div>
                            <div className="p-4 table-wrap align-left">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Title</StyledTableCell>
                                                <StyledTableCell align="right">Price</StyledTableCell>
                                                <StyledTableCell align="right">Air Fare</StyledTableCell>
                                                <StyledTableCell align="right">Net Price (INR)</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {currentTour.PriceDetails.map((row) => (
                                                <StyledTableRow key={row.Id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.Title}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{row.Price}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.AirFare}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.NetPrice}</StyledTableCell>

                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
    else {
        return (<div style={{ margin: '10vh' }}><strong><p>No Tours Found for {match.params.title}</p></strong></div>)
    }
}

export default Tour;