import React from "react";
import moreimage from "../assets/swamisworld_more.PNG";
import upcominTripImages from "../assets/Swamisworld_upcoming_trips.PNG";
import upcominTrip_2Images from "../assets/more_final_1.JPG";
import "../styles/more.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function More_sw() {
    return (
        <div className="more">
            <div
                className="moreTop"
                style={{ backgroundImage: `url(${moreimage})` }}
            ></div>
            <div className="moreBottom">
            <div
                className="upcomingtrips"
                style={{ backgroundImage: `url(${upcominTrip_2Images})` }}
            ></div>
           
            </div>
        </div>
    );
}

export default More_sw;
